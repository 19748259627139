<template>
  <div id="login" class="login container">

    <b-overlay :show="showOverlay" rounded="sm" variant="light" spinner-variant="secondary">
      <b-card class="card">
        <b-form>

          <div class="row">
            <div class="col pb-1">
              <b-img class="image" :src="require('./../assets/gfmlogo.png')" fluid alt="Responsive image"
                width="100%"></b-img>
            </div>
          </div>

          <!-- <b-button variant="link" class="text-decoration-none" @click="goBack">
              <b-icon icon="chevron-left" aria-hidden="true"></b-icon>
              <b-img
                class="image"
                :src="require('./../assets/gfmlogo.png')"
                fluid
                alt="Responsive image"
                width="100%"
              ></b-img>
            </b-button> -->

        </b-form>
        <h3 align="justify">
          <b data-cy="title-login">Login</b>
        </h3>
        <p class="p">Enter your GFM credentials to login</p>
        <b-alert v-model="showAlert" variant="danger" dismissible>{{ alertMsg }}</b-alert>
        <b-form @submit.prevent="onSubmit">
          <b-form-group class="font-bold" id="input-group-1" label="E-mail" label-for="input-1">
            <b-form-input id="input-1" v-model="form.email" type="email" required placeholder="Enter e-mail address"
              v-bind:class="{ pwalert: showAlert }" autocomplete="email" data-cy="input-email"></b-form-input>
          </b-form-group>
          <b-form-group class="font-bold" id="input-group-2" label="Password" label-for="input-2">
            <b-form-input id="input-2" v-model="form.password" type="password" placeholder="Enter password" required
              v-bind:class="{ pwalert: showAlert }" data-cy="input-password"></b-form-input>
          </b-form-group>
          <b-button type="submit" variant="primary" block data-cy="submit-login">Login</b-button>
          <div class="mt-1"></div>
          <router-link to="/password-reset" tag="b-link" data-cy="forgot-password">Forgot Password?</router-link>
          <span>&nbsp;or&nbsp;</span>
          <router-link to="/register" tag="b-link" data-cy="register">Register</router-link>
        </b-form>

        <div class="d-flex mt-3 mb-3">
          <hr class="my-auto flex-grow-1 text-grey-ligh ">
          <div class="px-4 text-grey-ligh ">or</div>
          <hr class="my-auto flex-grow-1 text-grey-ligh ">
        </div>
        <b-button block @click="glofasLogin()" data-cy="signin-glofas">Sign in with GloFAS</b-button>
        <b-button block @click="efasLogin()" data-cy="signin-efas">Sign in with EFAS</b-button>

      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import config from '@/config.js'

export default {
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      showAlert: false,
      alertMsg: "",
      showOverlay: false
    };
  },
  methods: {
    /* eslint-disable */
    onSubmit() {
      const formData = {
        email: this.form.email,
        password: this.form.password
      };
      this.showOverlay = true;
      this.$store.dispatch("signin", formData).then(
        response => {
          this.$store.commit("setUserType", config.GFM_USER);
          this.$store.commit("setViewVisibleForGlofas", true);
          this.$router.push(this.$route.query.redirect || "/");
          this.showOverlay = false;
          localStorage.setItem('login_src', "gfm");
        },
        error => {
          console.log("Error:", error);
          this.alertMsg = error.response.data.message.error_definition.message;
          this.showAlert = true;
          this.showOverlay = false;
        }
      );
    },
    /* eslint-enable */
    goBack() {
      this.$router.go(-1)
    },
    /**
     * Eventhandler for the glofas login button
     */
    glofasLogin() {
      localStorage.setItem('login_src', "glofas");
      this.$glofasAuth.login();
    },
    /**
     * Eventhandler for the efas login button
     */
    efasLogin() {
      localStorage.setItem('login_src', "efas");
      this.$efasAuth.login();
    }
  },
  mounted() {
    this.form.email = ""
  }
};
</script>

<style scoped>
.login {
  width: 400px;
  padding-top: 30px;
}

.card {
  border-color: #212529;
  border-width: 2px;
}

.p {
  font-size: 14px;
}

.pwalert {
  border-color: rgba(240, 36, 36, 0.397);
  border-width: 1px;
}

.alert {
  margin-top: 5px;
  margin-bottom: 5px;
}

.btn .b-icon.bi {
  font-size: 250%;
  vertical-align: middle;
}

.btn-link {
  color: #000000;
}

.text-grey-ligh {
  color: #BDC9D4;
  font-weight: bold;
}

.font-bold {
  font-weight: bold;
}
</style>
