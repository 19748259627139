<template>
  <div>
    <b-navbar id="top-nav-bar" fixed="top" toggleable="lg" type="dark" variant="dark">
      <b-navbar-brand @click="nabvarImgClicked">
        <img id="navbar-img" src="./../assets/gfmlogo.png" alt="">
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav v-if="!userSite">
          <b-nav-item>
            <router-link exact class="nav-link" :to="{ name: 'aoi' }">AOI</router-link>
          </b-nav-item>
          <b-nav-item>
            <router-link exact class="nav-link" :to="{ name: 'products2', params: { id: null } }" data-cy="link-products">Products</router-link>
            <!-- <router-link exact class="nav-link" :to="{ name: 'products2'}">Products</router-link> -->
          </b-nav-item>
          <b-nav-item>
            <router-link exact class="nav-link" :to="{ name: 'notifications' }">Notification Manager</router-link>
          </b-nav-item>
        </b-navbar-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto nabvar-icon" v-if="!userSite">
          <b-nav-form>
            <b-button @click="glofas" variant="warning" size="sm" class="btn-glofas mr-3 mb-1">GloFAS Portal</b-button>
            <b-button @click="efas" variant="warning" size="sm" class="btn-glofas mr-3 mb-1">EFAS Portal</b-button>
          </b-nav-form>
          <div>
            <div right class="mr-2">
              <HealthState/>
            </div>
          </div>
          <b-nav-item-dropdown right>
            <!-- Using 'button-content' slot -->
            <template #button-content>
              <em><b-icon icon="journal-text"></b-icon></em>
            </template>
            <b-dropdown-item @click="showHelp('pum')">Product user manual</b-dropdown-item>
            <b-dropdown-item @click="showHelp('pdd')">Product definition document</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown right>
            <template #button-content>
              <em><b-icon icon="three-dots-vertical"></b-icon></em>
            </template>
            <b-dropdown-item :to="{ name: 'code-snippet' }">Code snippet</b-dropdown-item>
            <b-dropdown-item disabled :to="{ name: 'archive-download' }">Archive download</b-dropdown-item>
          </b-nav-item-dropdown>
          <Notifications />
          <b-nav-item-dropdown right>
            <!-- Using 'button-content' slot -->
            <template #button-content>
              <em><b-icon icon="person-fill"></b-icon></em>
            </template>
            <b-dropdown-item class="" to="/profile"><b-icon icon="person"
                shift-h="-2"></b-icon><span>&nbsp;&nbsp;Profile</span></b-dropdown-item>
            <b-dropdown-item href="#" @click="signout"><b-icon icon="box-arrow-in-right" shift-h="-3"
                scale="0.9"></b-icon>&nbsp;&nbsp;Sign Out</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import Notifications from './Notifications.vue';
import HealthState from './HealthState.vue';

export default {
  name: "Navbar",
  components: {
    Notifications,
    HealthState
  },
  data() {
    return {

    };
  },
  computed: {
    userSite() {
      return this.$store.getters.userSite;
    },
    loginSrc() {
      return this.$store.getters.getLoginSource;
    }
  },
  mounted() {},
  methods: {
    nabvarImgClicked() {
      //this.$router.go(this.$router.currentRoute)
      window.open(process.env.VUE_APP_GLOFAS_PORTAL, '_blank');
    },
    signout() {
      this.$store.dispatch("signout");
    },
    glofas() {
      console.log("glofas");
      //window.open("https://stage.globalfloods.eu/glofas-forecasting/", '_blank');
      window.open(process.env.VUE_APP_GLOFAS_PORTAL, '_blank');
    },
    efas() {
      console.log("efas");
      window.open(process.env.VUE_APP_EFAS_PORTAL, '_blank');
    },
    showHelp(docType) {
      if (docType == 'pum') {
        window.open(process.env.VUE_APP_GFM_MANUAL_PUM, '_blank');
      } else if (docType == 'pdd') {
        window.open(process.env.VUE_APP_GFM_MANUAL_PDD, '_blank');
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.healthCheck)
  }
}
</script>

<style scoped>
#navbar-img:hover {
  cursor: pointer;
}

.navbar {
  /*min-height: 80px !important;*/
}

.navbar-brand {
  /*line-height: 80px;*/
}

.nav-link {
  padding-top: 0px;
  padding-bottom: 0px;
}

.navbar-brand img {
  height: 40px;
  width: auto;
  float: left;
  margin-right: 10px;
}

span.notification-badge {
  position: relative;
  top: -10px;
  right: 10px;
  margin-right: -8px;
}

.router-link-exact-active {
  font-weight: 700;
  color: #f6aa34 !important;
}

.router-link-active {
  font-weight: 700;
  color: #f6aa34 !important;
}

.notification-box {
  padding-top: 4px;
  padding-bottom: 4px;
}

.scrollable-menu {
  height: auto;
  max-height: 600px;
  overflow-x: hidden;
  width: 250px !important;
}

.fs14 {
  font-size: 14px;
}

.text-color-alert-title {
  color: #007bff;
  font-size: 14px;
}

#notification-header {
  padding-left: 24px;
  font-weight: 400;
  font-size: 20px;
}

.btn-glofas {
  background-color: #f6aa34;
  border-color: #DC972E;
}

.btn-glofas:focus,
.btn-glofas:active {
  outline: none !important;
  box-shadow: none;
}

.navbar-icon {
  margin-bottom: 8px;
  margin-top: 8px;
}
</style>
