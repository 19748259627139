<template>
  <div id="registration" class="login container">
    <b-card class="card">
      <b-form>
        <div class="row">
          <div class="col my-auto">
            <b-img
              class="image"
              :src="require('./../assets/gfmlogo.png')"
              fluid
              alt="Responsive image"
              width="100%"
            ></b-img>
          </div>
          <div class="col text-right">
            <b-button
              variant="link"
              class="text-decoration-none"
              @click="goBack"
            >
              <b-icon
                icon="chevron-left"
                class="toggle-icon rounded p-1"
                aria-hidden="true"
              ></b-icon>
            </b-button>
          </div>
        </div>
        <!-- <b-button variant="link" class="text-decoration-none" @click="goBack">
          <b-icon icon="chevron-left" aria-hidden="true"></b-icon>
          <b-img
            class="image"
            :src="require('./../assets/gfmlogo.png')"
            fluid
            alt="Responsive image"
            width="100%"
          ></b-img>
        </b-button> -->
      </b-form>
      <h3 align="justify">
        <b data-cy="title-register">Register</b>
      </h3>
      <p class="p">Create your GFM profile to register</p>
      <b-alert v-model="showPasswordAlert" variant="danger" dismissible data-cy="alert-show-password">{{
        failureText
      }}</b-alert>
      <b-alert v-model="showSuccess" variant="success" dismissible data-cy="alert-show-success"
        >We sent you an email to confirm your registration.</b-alert
      >
      <b-alert v-model="showEmailAlert" variant="warning" dismissible data-cy="alert-show-email"
        >This email is already in use. If you forgot your password click
        <b-link to="/password-reset" data-cy="link-to-password-reset">here</b-link></b-alert
      >
      <b-form @submit.prevent="onSubmit" v-if="!showSuccess">
        <!-- Mail information -->
        <b-form-group>
          <label class="label-style">E-mail address</label>
          <b-form-input
            v-model="formData.mail"
            placeholder="Enter e-mail address"
            autocomplete="off"
            required
            type="email"
            data-cy="input-email"
          ></b-form-input>
        </b-form-group>

        <!-- First name and last name -->
        <div class="row">
          <div class="col col-6">
            <b-form-group>
              <label class="label-style">First Name</label>
              <b-form-input
                v-model="formData.firstName"
                placeholder="Enter first name"
                autocomplete="given-name"
                required
                data-cy="input-first-name"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col col-6">
            <b-form-group>
              <label class="label-style">Last Name</label>
              <b-form-input
                v-model="formData.lastName"
                placeholder="Enter last name"
                autocomplete="family-name"
                required
                data-cy="input-last-name"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>

        <!-- Country -->
        <div class="row">
          <div class="col col">
            <b-form-group autocomplete="off">
              <label class="label-style">Country</label>
              <b-form-select
                v-model="formData.country"
                :options="countryOptions"
                required
                data-cy="select-country"
                autocomplete="off"
              >
                <template #first>
                  <b-form-select-option :value="null" disabled
                    >Select country</b-form-select-option
                  >
                </template>
              </b-form-select>
            </b-form-group>
          </div>
        </div>


        <!-- Organization information (name) -->
        <div class="row">
          <div class="col">
            <b-form-group>
              <label class="label-style">Organization Name</label>
              <b-form-input
                v-model="formData.organizationName"
                placeholder="Enter organization name"
                autocomplete="organization"
                required
                data-cy="input-organization-name"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>

        <!-- Organization information (type) -->
        <div class="row">
          <div class="col col">
            <b-form-group>
              <label class="label-style">Organization Type</label>
              <b-form-select
                v-model="formData.organizationType"
                :options="organizationTypeOptions"
                required
                data-cy="select-organization-type"
              >
                <template #first>
                  <b-form-select-option :value="null" disabled
                    >Select organization type</b-form-select-option
                  >
                </template>
              </b-form-select>
            </b-form-group>
          </div>
        </div>

        <b-form-group id="input-group-5" label-for="input-5">
          <label class="label-style">Password</label>
          <b-form-input
            id="input-5"
            v-model="formData.password"
            type="password"
            required
            data-cy="input-password"
            v-bind:class="{ pwalert: showPasswordAlert }"
          ></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-6" label-for="input-6">
          <label class="label-style">Confirm Password</label>
          <b-form-input
            id="input-6"
            v-model="formData.password2"
            type="password"
            required
            data-cy="input-confirm-password"
            v-bind:class="{ pwalert: showPasswordAlert }"
          ></b-form-input>
        </b-form-group>

        <div class="mt-2">
          <b-button type="submit" variant="primary" block data-cy="submit-signup">Sign up</b-button>
        </div>
      </b-form>
      <b-button
        type="submit"
        variant="primary"
        block
        v-if="showSuccess"
        @click="resentEmail"
        >Re-send Email</b-button
      >
    </b-card>
  </div>
</template>

<script>
import axios_services from "@/axios/axios-services.js";

export default {
  data() {
    return {
      formData: {
        mail: "",
        firstName: "",
        lastName: "",
        organizationName: "",
        organizationType: null,
        country: null,
        password: "",
        password2: "",
      },
      countryOptions: [],
      organizationTypeOptions: [],
      failureText: "",
      showPasswordAlert: false,
      showSuccess: false,
      showEmailAlert: false,
    };
  },
  methods: {
    onSubmit() {
      if (!this.formData.password.match("(?=.{8,})")) {
        this.showPasswordAlert = true;
        this.failureText = "Your password must be at least 8 characters long";
      } else if (this.formData.password == this.formData.password2) {
        /* eslint-disable */
        const formData = {
          first_name: this.formData.firstName,
          last_name: this.formData.lastName,
          email: this.formData.mail.toLowerCase(),
          password: this.formData.password,
          company_name: this.formData.organizationName,
          country: this.formData.country,
          company_type: this.formData.organizationType,
        };

        this.$store
          .dispatch("register", formData)
          .then((response) => {
            this.showSuccess = true;
            this.showPasswordAlert = false;
          })
          .catch((error) => {
            console.log("Error:", error.response);
            if (error.response.status == 400) {
              this.showEmailAlert = true;
              this.showPasswordAlert = false;
            }
          });
        /* eslint-enable */
      } else {
        this.showPasswordAlert = true;
        this.failureText = "Passwords must be the same";
      }
    },
    resentEmail() {
      const formData = {
        email: this.formData.email,
      };
      this.$store.dispatch("resentEmail", formData);
    },
    goBack() {
      this.$router.go(-1);
    },
  },
  mounted() {
    axios_services.get("utils/countries").then((response) => {
      this.countryOptions = response.data;
    });
    axios_services.get("utils/organization_types").then((response) => {
      this.organizationTypeOptions = response.data;
    });
  },
};
</script>

<style scoped>
.login {
  width: 800px;
  padding-top: 30px;
}

.card {
  border-color: #343a40 !important;
  border-width: 2px;
}

.p {
  font-size: 14px;
}

.pwalert {
  border-color: rgba(240, 36, 36, 0.397);
  border-width: 1px;
}

.alert {
  margin-top: 5px;
  margin-bottom: 5px;
}

.btn .b-icon.bi {
  font-size: 250%;
  vertical-align: middle;
}

.btn-link {
  color: #000000;
}

/**
 * Sidebar icon hover styling
 */
.toggle-icon:hover {
  cursor: pointer;
  background-color: #f4f4f4;
}

.card .label-style {
  font-size: 12px;
  text-transform: uppercase;
  color: #9a9a9a;
  margin-bottom: 4px;
}

.card-text-font {
  font-style: normal;
}
</style>
