import Vue from "vue";
import App from "./App.vue";
import store from "./store/store.js";

import glofasAuth from "@/glofas-auth.js";
import efasAuth from "@/efas-auth.js";
import reportService from "@/report-service.js";
import codeSnippet from "@/code-snippet.js";

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import { BootstrapVueIcons } from "bootstrap-vue";
import router from "@/router/router.js";

Vue.config.productionTip = false;

// Add Bootstrap dependencies
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

// Add search-select component
import "vue-search-select/dist/VueSearchSelect.css";

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(IconsPlugin); // Optionally install the BootstrapVue icon components plugin
Vue.use(glofasAuth);
Vue.use(efasAuth);
Vue.use(reportService);
Vue.use(codeSnippet);

// Importing the global css file
import "@/assets/css/global.css";

// cypress testing - added by Ruth User
// import { createApp } from './setup.js'

// Create an event-bus
export const eventBus = new Vue();

new Vue({
  store,
  router,
  render: (h) => h(App),
  // createApp
}).$mount("#app");

